// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react"

const config = {
  useSystemColorMode: true,
  initialColorMode: "light",
}

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  purple: {
    50: "#CFCEE9",
    100: "#BFBDE1",
    200: "#A9A8D8",
    300: "#8D8ACA",
    400: "#6664B9",
    500: "#434190",
    600: "#3E4174",
    700: "#373C60",
    800: "#303750",
    900: "#2B3143",
  },
  blue: {
    50: "#EFF6FF",
    100: "#DBEAFE",
    200: "#BFDBFE",
    300: "#93C5FD",
    400: "#60A5FA",
    500: "#3B82F6",
    600: "#2563EB",
    700: "#1D4ED8",
    800: "#1E40AF",
    900: "#142860", //"#182e6e", //"#1b347c",
  },
}

const components = {}

const theme = extendTheme({
  config,
  styles: {
    global: {
      // "html, body": {
      //   bg: "blue.900",
      //   color: "white",
      // },
      // a: {
      //   color: "teal.500",
      // },
    },
  },
  fonts: {
    body:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    // body: '"Open Sans", sans-serif',
    heading:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    // heading: '"Open Sans", sans-serif',
    // mono: "Menlo, monospace",
  },
  colors,
  components,
})

export default theme
